var render = function () {
  var _vm$report$data;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.report ? _c('div', {
    staticClass: "justify-content-center align-items-center pb-10"
  }, [_vm.report.status === 'preparing' ? _c('Spinner', {
    staticClass: "text-center mb-5"
  }, [_vm._v("Generating report... This should only take a few minutes.")]) : _vm.report.status === 'generating' ? _c('Spinner', {
    staticClass: "text-center mb-5"
  }, [_vm._v("Generating report... This should only take a few minutes.")]) : _vm.report.status === 'ready' ? _c('div', [_c('div', {
    staticClass: "d-flex mb-3"
  }, [_c('div', {
    staticClass: "text-center flex-grow-1 font-w600 font-size-h4 mb-0"
  }, [_vm._v(_vm._s(_vm.report.fileName))]), _c('div', [_c('a', {
    staticClass: "btn btn-primary",
    class: {
      disabled: !_vm.downloadUrl
    },
    attrs: {
      "href": _vm.downloadUrl
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-download"
  }), _vm._v(" CSV")])])]), _c('div', {
    staticClass: "ChartOptions",
    class: {
      'ChartOptions--hidden': _vm.hideOptions
    }
  }, [_c('div', {
    staticClass: "ChartOptions-toggle bg-light rounded-md px-3 py-2 pb-4 font-w600",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.hideOptions = !_vm.hideOptions;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-lg",
    class: _vm.hideOptions ? 'fa-chart-bullet' : 'fa-chevron-down'
  }), _c('span', {
    staticClass: "font-size-lg ml-2",
    class: {
      'd-none': !_vm.hideOptions
    }
  }, [_vm._v("Chart Options")])]), _c('div', {
    staticClass: "px-3 py-3 bg-light rounded-md",
    class: {
      'd-none': _vm.hideOptions
    }
  }, [_c('div', {
    staticClass: "d-flex border-bottom ml-3 mb-3 pb-2"
  }, [_c('h3', {
    staticClass: "mb-0 flex-grow-1"
  }, [_vm._v("Chart Options")])]), _c('div', {
    staticClass: "d-flex flex-wrap"
  }, [_c('div', {
    staticClass: "w-100"
  }), _vm._l(_vm.chartCustomizationOptions, function (option) {
    return _c('div', {
      key: option.name,
      staticClass: "ChartOption px-3 py-2"
    }, [_c('div', {
      staticClass: "font-w600 mr-2"
    }, [_vm._v(_vm._s(option.label))]), _c('div', {
      staticClass: "d-flex align-items-center py-2"
    }, _vm._l(option.items, function (item) {
      return _c('a', {
        key: item.value,
        staticClass: "font-w600 px-3 py-1 no-wrap",
        class: {
          'bg-primary text-white border rounded-md': _vm.chartForm[option.name] === item.value
        },
        attrs: {
          "href": "#"
        },
        on: {
          "click": function click($event) {
            $event.preventDefault();
            _vm.chartForm[option.name] = item.value;
          }
        }
      }, [item.icon ? _c('i', {
        class: "fa-solid ".concat(item.icon, " mr-1")
      }) : _vm._e(), _vm._v(" " + _vm._s(item.label))]);
    }), 0)]);
  })], 2)])]), (_vm$report$data = _vm.report.data) !== null && _vm$report$data !== void 0 && _vm$report$data.series && _vm.chartForm.type ? _c('Chart', {
    key: Object.values(_vm.chartForm).toString(),
    staticClass: "mb-4",
    staticStyle: {
      "height": "650px",
      "width": "100%"
    },
    attrs: {
      "option": _vm.consumptionChart
    }
  }) : _vm._e()], 1) : _vm.report.status === 'error' ? _c('div', [_c('div', {
    staticClass: "font-w600 font-size-h4 mb-2"
  }, [_c('i', {
    staticClass: "fa fa-times-circle text-danger"
  }), _vm._v(" Report failed to generate.")]), _c('div', [_vm._v("Please contact support.")])]) : _vm._e()], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }