<template>
  <div>
    <div v-if="report" class="justify-content-center align-items-center pb-10">
      <Spinner v-if="report.status === 'preparing'" class="text-center mb-5">Generating report... This should only take a few minutes.</Spinner>
      <Spinner v-else-if="report.status === 'generating'" class="text-center mb-5">Generating report... This should only take a few minutes.</Spinner>

      <div v-else-if="report.status === 'ready'">
        <div class="d-flex mb-3">
          <div class="text-center flex-grow-1 font-w600 font-size-h4 mb-0">{{ report.fileName }}</div>
          <div>
            <a class="btn btn-primary" :class="{ disabled: !downloadUrl }" :href="downloadUrl"><i class="fa fa-fw fa-download"></i> CSV</a>
          </div>
        </div>

        <div class="ChartOptions" :class="{ 'ChartOptions--hidden': hideOptions }">
          <div class="ChartOptions-toggle bg-light rounded-md px-3 py-2 pb-4 font-w600" @click.prevent="hideOptions = !hideOptions">
            <i class="fa fa-lg" :class="hideOptions ? 'fa-chart-bullet' : 'fa-chevron-down'"></i>
            <span :class="{ 'd-none': !hideOptions }" class="font-size-lg ml-2">Chart Options</span>
          </div>
          <div class="px-3 py-3 bg-light rounded-md" :class="{ 'd-none': hideOptions }">
            <div class="d-flex border-bottom ml-3 mb-3 pb-2">
              <h3 class="mb-0 flex-grow-1">Chart Options</h3>
            </div>
            <div class="d-flex flex-wrap">
              <div class="w-100"></div>
              <div v-for="option in chartCustomizationOptions" :key="option.name" class="ChartOption px-3 py-2">
                <div class="font-w600 mr-2">{{ option.label }}</div>
                <div class="d-flex align-items-center py-2">
                  <a
                    v-for="item in option.items"
                    :key="item.value"
                    href="#"
                    class="font-w600 px-3 py-1 no-wrap"
                    :class="{ 'bg-primary text-white border rounded-md': chartForm[option.name] === item.value }"
                    @click.prevent="chartForm[option.name] = item.value"
                    ><i v-if="item.icon" :class="`fa-solid ${item.icon} mr-1`"></i> {{ item.label }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <Chart
          v-if="report.data?.series && chartForm.type"
          :key="Object.values(chartForm).toString()"
          class="mb-4"
          style="height: 650px; width: 100%"
          :option="consumptionChart"
        />
      </div>

      <div v-else-if="report.status === 'error'">
        <div class="font-w600 font-size-h4 mb-2"><i class="fa fa-times-circle text-danger"></i> Report failed to generate.</div>
        <div>Please contact support.</div>
      </div>
    </div>
    <!-- <div class="col-lg-4">
        <div class="alert alert-info">
          <h3 class="font-size-h5 mb-2"><i class="fad fa-hourglass mr-1"></i> Taking a while to generate?</h3>
          <p class="mb-0">You will get a notification when this report is ready to download, so you may safely navigate away from this page.</p>
        </div>
      </div> -->
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Chart from '@/components/echarts/Chart';
import Spinner from '@/components/SpinnerLogo';

import { chartCustomizationOptions } from './options';
import { chartColours, granularityFormatMap } from '../../lib/consumption';

export default {
  name: 'ReportChart',
  components: {
    Chart,
    Spinner
  },
  data() {
    return {
      poll: {},
      fetchedDownloadUrl: false,
      downloadUrl: '',
      chartForm: {},
      hideOptions: false
    };
  },
  computed: {
    ...mapGetters({
      report: 'report/report',
      loadingAction: 'report/loadingAction'
    }),
    chartCustomizationOptions() {
      return chartCustomizationOptions
        .filter(
          o =>
            (!o.includeReportTypes || o.includeReportTypes.includes(this.report.metadata.reportType)) &&
            (!o.excludeReportTypes || !o.excludeReportTypes.includes(this.report.metadata.reportType))
        )
        .filter(
          o => (!o.includeTypes || o.includeTypes.includes(this.chartForm.type)) && (!o.excludeTypes || !o.excludeTypes.includes(this.chartForm.type))
        );
    },
    consumptionChart() {
      const axisLabel = {};

      if (this.chartForm.axisInterval) {
        axisLabel.interval = this.chartForm.axisInterval;
      }

      if (this.chartForm.axisRotate) {
        axisLabel.rotate = this.chartForm.axisRotate;
      }

      const options = {
        color: chartColours,
        tooltip: {
          trigger: 'axis',
          formatter: params => {
            const currentSeries = series.find(s => s.name === params[0].seriesName);
            const units = currentSeries.tags.units ? ` ${currentSeries.tags.units}` : '';

            if (!this.report.data.categories) {
              const s = params
                .map(param => {
                  const currentSeries = param.seriesName.toLocaleString();
                  const value = param.value[1] !== null ? param.value[1].toLocaleString() : null;
                  const valueHtml = value ? `<strong>${value}</strong> ${units}` : '<strong>No data</strong>';
                  return `<i class="fa fa-circle fa-fw fa-sm mr-1" style="color: ${param.color}"></i>${currentSeries}: ${valueHtml}`;
                })
                .join('<br />');

              const date = moment.utc(params[0].axisValue).format(granularityFormatMap[this.report.metadata.granularity].format);
              return `<strong>${date}</strong><br /><br />${s}`;
            }

            const s = params
              .map(param => {
                const seriesName = param.seriesName.toLocaleString();
                const value = param.value !== null && param.value !== undefined ? param.value.toLocaleString() : null;
                const valueHtml = value ? `<strong>${value}</strong> ${units}` : '<strong>No data</strong>';
                return `<i class="fa fa-circle fa-fw fa-sm mr-1" style="color: ${param.color}"></i>${seriesName}: ${valueHtml}`;
              })
              .join('<br />');

            return `<strong>${params[0].axisValue}</strong><br />${s}`;
          },
          position: function (pt) {
            return [pt[0], '10%'];
          }
        },
        toolbox: {
          feature: {
            restore: { show: true },
            saveAsImage: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['stack'] },
            mark: { show: true }
          }
        },
        xAxis: this.report.data.categories
          ? [
              {
                type: 'category',
                axisTick: { show: false },
                data: this.report.data.categories,
                axisLabel
              }
            ]
          : [
              {
                type: 'time'
              }
            ],
        yAxis: {
          name: 'kWh',
          type: 'value'
        },
        animationEasing: 'elasticOut'
      };

      const baseSeriesOptions = {
        type: this.chartForm.type
      };

      if (this.chartForm.type === 'line') {
        baseSeriesOptions.smooth = false;
      }

      if (this.chartForm.type === 'area') {
        baseSeriesOptions.areaStyle = {};
        baseSeriesOptions.type = 'line';
        baseSeriesOptions.smooth = false;
      }

      const baseSeries = this.report.data.series
        .filter(s => !s.data.every(item => item === null))
        .map(s => {
          return {
            ...baseSeriesOptions,
            ...s,
            isBase: true
          };
        });

      let series = [...baseSeries];

      const isMultiSeries = baseSeries.filter(s => !s.isPrev).length > 1;
      const isMultiPeriod = this.report.metadata.chartOptions.comparePeriod;

      const altLineOptions = (c = '#65C198') => ({
        symbol: 'roundRect',
        lineStyle: {
          color: c,
          type: 'dashed'
        },
        itemStyle: {
          borderColor: c,
          color: c
        }
      });

      if (this.chartForm.type === 'heatmap' && !isMultiPeriod) {
        options.yAxis = {
          type: 'category',
          data: baseSeries.map(s => s.name),
          axisLabel: {
            interval: 0
          }
        };

        const heatmapData = [];

        baseSeries.forEach((s, i) => {
          s.data.forEach((item, j) => {
            heatmapData.push([j, i, item]);
          });
        });

        return {
          ...options,
          tooltip: {
            position: 'top'
          },
          visualMap: {
            min: Math.min(...heatmapData.map(item => item[2])),
            max: Math.max(...heatmapData.map(item => item[2])),
            calculable: true,
            orient: 'horizontal',
            left: 'center',
            bottom: '-2',
            show: this.chartForm.heatmapSlider
          },
          series: [
            {
              type: 'heatmap',
              data: heatmapData.map(item => [item[0], item[1], item[2] || '-']),
              label: {
                show: this.chartForm.heatmapLabels
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
      }

      // Simple Average line
      if (this.chartForm.average && isMultiSeries && this.report.metadata.reportType !== 'hh') {
        if (this.chartForm.average === 'only') {
          series = [];
        }

        const avgSeriesData = baseSeries
          .filter(s => !s.isPrev) // Filter out accounts
          .reduce((acc, s) => {
            if (!acc) return s.data.map(item => item);
            return acc.map((item, i) => item + s.data[i]);
          }, null)
          .map(item => item / series.filter(s => !s.isPrev).length);

        series.push({
          ...baseSeriesOptions,
          ...altLineOptions(),
          type: 'line',
          name: 'Average',
          data: avgSeriesData,
          isBase: false,
          markArea: {
            itemStyle: {
              color: 'rgba(200, 150, 100, 0.08)'
            },
            data: [[{ xAxis: '07:00' }, { xAxis: '18:00' }]]
          },
          tags: {
            units: baseSeries.filter(s => !s.isPrev)[0].tags.units
          }
        });

        if (isMultiPeriod) {
          const avgPrevSeries = baseSeries
            .filter(s => s.isPrev) // Filter out accounts
            .reduce((acc, s) => {
              if (!acc) return s.data.map(item => item);
              return acc.map((item, i) => item + s.data[i]);
            }, null)
            .map(item => item / series.filter(s => s.isPrev).length);

          series.push({
            ...baseSeriesOptions,
            ...altLineOptions('#1F303D'),
            type: 'line',
            name: 'Average (Prev Period)',
            data: avgPrevSeries,
            isBase: false,
            tags: {
              units: baseSeries.filter(s => s.isPrev)[0].tags.units
            }
          });
        }
      }

      // HH - Weekday and Weekend Avg.
      if (this.chartForm.average && this.report.metadata.reportType === 'hh') {
        if (this.chartForm.average === 'only') {
          series = [];
        }

        // Weekday Avg.
        const weekdayFilter = s => !s.name.includes('Sat') && !s.name.includes('Sun'); // Filter out weekends
        const seriesFilter = s => !s.data.every(item => item === null) && !s.isPrev; // Filter out accounts with only null data (null = missing)

        const weekdaySeries = baseSeries.filter(s => weekdayFilter(s) && seriesFilter(s));
        const avgWeekdaySeries = weekdaySeries
          .reduce((acc, s) => {
            if (!acc) return s.data.map(item => item);
            return acc.map((item, i) => item + s.data[i]);
          }, null)
          .map(item => item / weekdaySeries.filter(s => !s.isPrev).length);

        series.push({
          ...baseSeriesOptions,
          ...altLineOptions(),
          type: 'line',
          name: 'Weekday Average',
          data: avgWeekdaySeries,
          isBase: false,
          markArea: {
            itemStyle: {
              color: 'rgba(200, 150, 100, 0.05)'
            },
            data: [[{ xAxis: '07:00' }, { xAxis: '23:30' }]]
          },
          tags: {
            units: weekdaySeries[0].tags.units
          }
        });

        // Weekend Avg.
        const weekendSeries = baseSeries.filter(s => !weekdayFilter(s) && seriesFilter(s));
        const avgWeekendSeries = (weekendSeries || [])
          .reduce((acc, s) => {
            if (!acc) return (s.data || []).map(item => item);
            return acc.map((item, i) => item + s.data[i]);
          }, null)
          .map(item => item / weekendSeries.filter(s => !s.isPrev).length);

        series.push({
          ...baseSeriesOptions,
          ...altLineOptions('#1F303D'),
          type: 'line',
          name: 'Weekend Average',
          data: avgWeekendSeries,
          isBase: false,
          tags: {
            units: weekendSeries[0].tags.units
          }
        });
      }

      // Cumulative Calc
      if (this.chartForm.cumulative) {
        const mainSeries = series.filter(s => !s.isPrev);

        // Convert data to cumulative
        series = mainSeries.map(s => {
          return {
            ...s,
            data: s.data.reduce((acc, item, i) => {
              if (i === 0) {
                acc.push(item);
              } else {
                acc.push(acc[i - 1] + item);
              }
              return acc;
            }, [])
          };
        });

        if (isMultiPeriod) {
          const prevSeries = series.filter(s => s.isPrev);

          // Convert data to cumulative
          const cumulativePrevSeries = prevSeries.map(s => {
            return {
              ...s,
              data: s.data.reduce((acc, item, i) => {
                if (i === 0) {
                  acc.push(item);
                } else {
                  acc.push(acc[i - 1] + item);
                }
                return acc;
              }, [])
            };
          });

          series = series.concat(cumulativePrevSeries);
        }
      }

      // HH - Max Demand
      // if (this.chartForm.maxDemand && this.report.metadata.reportType === 'hh') {
      //   const maxDemandData = baseSeries
      //     .filter(s => !s.data.every(item => item === null) && !s.isPrev && s.isBase) // Filter out accounts with only null data
      //     .reduce((acc, s) => {
      //       if (!acc) {
      //         acc = s.data.map(item => item);
      //       }

      //       s.data.forEach((item, i) => {
      //         if (item > acc[i]) {
      //           acc[i] = item;
      //         }
      //       });

      //       return acc;
      //     }, null);

      //   const maxDemandSeries = {
      //     ...baseSeriesOptions,
      //     ...altLineOptions('#F25F5C'),
      //     type: 'line',
      //     name: 'Max. Demand',
      //     data: maxDemandData,
      //     isBase: false,
      //     tags: {
      //       units: 'kW'
      //     }
      //   };

      //   series = series.concat(maxDemandSeries);
      // }

      if (this.chartForm.legend && this.report.data.categories) {
        options.legend = {
          data: series.map(s => s.name)
        };

        if (this.chartForm.legend === 'right') {
          options.legend.right = 0;
          options.legend.orient = 'vertical';
          options.legend.top = '40';
        }
      }

      return {
        ...options,
        series,
        grid: {
          left: '8%',
          right: series.length > 26 ? '20%' : '10%'
        }
      };
    }
  },
  beforeDestroy() {
    this.endPolling();
    this.resetState();
  },
  async mounted() {
    const { id } = this.$route.params;

    if (!id) {
      return this.$router.push({ name: 'reports' });
    }

    const report = await this.getReport({ id });

    chartCustomizationOptions.forEach(option => {
      this.$set(this.chartForm, option.name, option.default);
    });

    if (report?.status === 'ready') {
      this.getDownloadUrl();
    } else {
      this.startPolling();
    }

    if (report.metadata.reportType === 'hh') {
      this.$set(this.chartForm, 'legend', 'right');
    }
  },
  methods: {
    ...mapActions({
      getReport: 'report/get',
      download: 'report/download'
    }),
    ...mapMutations({
      resetState: 'report/RESET_STATE'
    }),
    async getDownloadUrl() {
      if (!this.fetchedDownloadUrl) {
        this.fetchedDownloadUrl = true;

        const download = await this.download({ id: this.report._id });

        this.downloadUrl = download.downloadUrl;
      }
    },
    startPolling() {
      this.poll = setInterval(async () => {
        const report = await this.getReport({ id: this.$route.params.id });
        if (report.status === 'ready' || report.status === 'error') {
          this.endPolling();
          this.getDownloadUrl();
        }
      }, 5000);
    },
    endPolling() {
      clearInterval(this.poll);
      this.poll = null;
    }
  }
};
</script>
<style>
.ChartOptions {
  position: fixed;
  z-index: 1000;
  bottom: 25px;
  right: 25px;

  width: calc(100% - 280px);

  &.hide {
    display: none;
  }
}

.ChartOptions-toggle {
  position: absolute;

  top: -35px;
  right: 0;

  z-index: -1;

  cursor: pointer;
}

.ChartOptions--hidden .ChartOptions-toggle {
  right: calc(50% - 150px);
  top: -20px;
}

.ChartOption {
  border-right: 2px solid #0000000a;
}
.ChartOption:last-child {
  border: none !important;
}
</style>
